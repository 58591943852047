





























































































import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import Toolbar from "@/components/Toolbar.vue";
import StruttureService, {Struttura, StrutturaRequest, StruttureResponse} from "@/services/StruttureService";
import {Filtro} from "@/Classes/FiltroClass";
import {DateTime} from "luxon";

@Component({
  components: {
    Toolbar,
  },
})
export default class ModStructure extends Vue{
  @Prop() readonly strutturaSelezionata!: Struttura
  struttura = null
  listaStrutture: StruttureResponse =<StruttureResponse>{}
  public datiStruttura:Struttura = {
    id: 0,
    nome: "",
    descrizione: "",
    sito_web: "",
    telefono: "",
    email:"",
    orario_apertura:  [],
    orario_chiusura:  [],
    annullabile: false,
    rimborsabile:false,
    ore_per_annullamento: 0,
    commento_obbligatorio:false,
    media:  [],
    comune:"",
    istat_comune: "",
    provincia: "",
    istat_provincia:"",
    servizi_di_cortesia:[],
    indirizzo: "",
    logo: "",
    aperto: true,
    posizione:{
      type:"",
      coordinates:[]
    },
    id_subscription: "",
    subscription_price: 0,
    subscription_period_end:""
  }
  public filtro = new Filtro();
  public giorniSettimana: string[] = ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"]


  formattaOrari(){
    for(let i=0; i< this.datiStruttura.orario_chiusura.length; i++){
      if(DateTime.invalid(DateTime.fromFormat(this.datiStruttura.orario_chiusura[i],"HH:mm:ss+00").toFormat("HH:mm"))){
        this.datiStruttura.orario_chiusura[i] = DateTime.fromFormat(this.datiStruttura.orario_chiusura[i],"HH:mm:ss+00").toFormat("HH:mm")
        this.datiStruttura.orario_apertura[i] = DateTime.fromFormat(this.datiStruttura.orario_apertura[i],"HH:mm:ss+00").toFormat("HH:mm")
      }
    }
  }

  async modifica(){

    for(let i=0; i< this.datiStruttura.orario_apertura.length; i++){
      this.datiStruttura.orario_chiusura[i] += ":00+00"
      this.datiStruttura.orario_apertura[i] += ":00+00"
    }

    const struttura: StrutturaRequest = {
      nome: this.datiStruttura.nome,
      descrizione: this.datiStruttura.descrizione,
      sito_web: this.datiStruttura.sito_web,
      telefono: this.datiStruttura.telefono,
      email: this.datiStruttura.email,
      orario_chiusura: this.datiStruttura.orario_chiusura,
      orario_apertura: this.datiStruttura.orario_apertura,
      annullabile: this.datiStruttura.annullabile,
      rimborsabile: this.datiStruttura.rimborsabile,
      ore_per_annullamento: this.datiStruttura.ore_per_annullamento,
      commento_obbligatorio: this.datiStruttura.commento_obbligatorio

    }

    const response = await StruttureService.updateStruttura(struttura, this.datiStruttura.id)
    if(response.error){
      this.$message.error(response.msg)
    } else {
      this.$message.success("modifica avvenuta con successo")
      this.CloseDialog();
    }
  }
  @Emit()
  CloseDialog() {
    this.$emit("CloseDialog")
  }

  @Watch('strutturaSelezionata')
  async ReloadData(newVal: Struttura)
  {
    await this.RiceviStrutture();

  }

  public async RiceviStrutture(){
    try{
      const response = await StruttureService.getStruttura(this.strutturaSelezionata.id);

      if(response.error) {
        this.$message.error(response.msg)
      } else{
        console.log( response.data[0].struttura.info)
        this.datiStruttura = response.data[0].struttura.info;
        if(response.data[0].struttura.info.ore_per_annullamento == null){
          //response.data[0].struttura.info.ore_per_annullamento = 0
        }
        this.formattaOrari()
      }
    }
    catch (err:any)
    {

    }
  }

  mounted(){
    this.RiceviStrutture()
  }
}
